import React, { useState, useEffect } from "react";

const Loader = ({ show }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    shouldRender && (
      <div
        style={{ animation: `${show ? "" : "fadeOut"} 1s` }}
        className="bg-white absolute absolute--fill flex justify-center items-center "
        onAnimationEnd={onAnimationEnd}
      >
        <div
          className="animate-flicker contain bg-center"
          style={{ backgroundImage: `url(/logo.svg)`, width: 300, height: 100 }}
        />
      </div>
    )
  );
};

export default Loader;
