import { graphql } from "gatsby";
import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "tachyons/css/tachyons.min.css";
import "./index.css";
import "../css/common.css";
import "animate.css/animate.css";

// import currentCommitHash from "../current-commit-hash";

import Seo from "../components/SeoComponent";
import Loader from "../components/ui/Loader";
import Header from "../components/Header";
import Banner from "../components/Banner";
import HomeSection from "../components/HomeSection";
import PremiumServices from "../components/PremiumServices";
import Presentation from "../components/Presentation";
import Prompt from "../components/Prompt";
import Services from "../components/Services";
import Director from "../components/Director";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

// TODO: Move this to gatsby config
const IMAGES_URLS = [
  "/images/slider04.jpg",
  "/images/Bandeau02_SR.jpg",
  "/images/Bandeau02_SR.png",
  "/images/Bandeau03_SR.jpg",
  "/images/Image_bandeau03_SR.jpg",
  "/images/premium-solutions.jpg",
  "/images/inclusive1.jpg",
  "/images/inclusive2.jpg",
  "/images/sr_banner.svg",
  "/images/phone-SR.png"
];

const Main = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const cacheImages = srcArray => {
    Promise.all(
      srcArray.map(
        src =>
          new Promise((resolve, reject) => {
            const img = new Image();

            img.src = src;
            img.onload = () => {
              resolve();
            };
            img.onerror = () => {
              reject();
            };
          })
      )
    )
      .then(() => {
        setTimeout(() => setIsLoading(false), 2000);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      cacheImages(IMAGES_URLS);
    }
  }, []);

  return (
    <>
      <Seo title={t("siteMetadata.name")} description={t("siteMetadata.description")} />
      <h1 className="dn">{t("siteMetadata.name")}</h1>
      <div className={`abel ${isLoading ? "dn" : "db"}`}>
        <Header />
        <Banner />
        <Element name="Home">
          <HomeSection />
          <PremiumServices />
        </Element>
        <Element name="Presentations" className="mv5">
          <Presentation />
          <div className="mv5">
            <Prompt />
          </div>
        </Element>
        <Element name="Services" className="mv5">
          <Services />
        </Element>
        <div className="mv5">
          <Director />
        </div>
        <Element name="Contact" className="mv5">
          <div className="mv5">
            <Contact />
          </div>
        </Element>
        <div className="mt5">
          <Footer />
        </div>
      </div>
      <Loader show={isLoading} />
      {/* <div className="dn" id="currentCommitHash">
        {currentCommitHash}
      </div> */}
    </>
  );
};

const App = () => <Main />;

export default App;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
