import React from "react";
import { useTranslation } from "react-i18next";
// import ScrollAnimation from "react-animate-on-scroll";

import "../css/HomeSection.css";

const HomeSection = () => {
  const { t } = useTranslation();
  return (
    <div className="mv5">
      <div className="section-home-title ph1 ph0-l">{t("home.slogan")}</div>
      <div className="section-home-text">{t("home.slogan2")}</div>
    </div>
  );
};

// const HomeSectionOld = () => {
//   const { t } = useTranslation();
//   return (
//     <div className="mw8 center flex-l flex-column">
//       <HomeSeparator />
//       <div className="w-100 ma2-l flex flex-column justify-center items-center">
//         <HomeParagraph animateIn="animate__bounceInUp" text={t("home.slogan")} />
//         <HomeSeparator />
//         <HomeParagraph animateIn="animate__bounceInUp" text={t("home.slogan2")} />
//       </div>
//       <HomeSeparator classNameAdded="mb5" />
//     </div>
//   );
// };

// const HomeSeparator = props => {
//   return <div className={`w-20 center bt bw1 b--moon-gray ${props.classNameAdded}`} />;
// };

// const HomeParagraph = props => {
//   return (
//     <ScrollAnimation animateIn={props.animateIn} duration={1}>
//       <p className="f3 tc fw6 pa0 lh-copy">{props.text}</p>
//     </ScrollAnimation>
//   );
// };

export default HomeSection;
