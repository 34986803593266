import React from "react";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

import "../css/Prompt.css";

const Prompt = () => {
  const { t } = useTranslation();

  return (
    <div className="w-100 bg-black white pv4">
      <div className="section-prompt cf">
        <div className="fl w-100 w-70-l pa3">
          <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
            <h2 className="section-prompt-title">{t("prompt.header")}</h2>
            <p className="section-prompt-text">{t("prompt.text")}</p>
          </ScrollAnimation>
        </div>
        <div className="fl w-100 w-30-l pa3 tc">
          <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
            <a
              href={`tel:${t("prompt.tel")}`}
              className="grow dib f4 fw6 no-underline bg-white hover-bg-navy black hover-white pv3 ph4 w-100"
            >
              {t("prompt.button")}
            </a>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="w-100 bg-black pv5 ph3 white">
  //     <div className="mw8 center flex-l">
  //       <div className="w-60-l w-100">
  //         <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
  //           <h2 className="f2 ttu tracked">{t("prompt.header")}</h2>
  //           <p className="ttu">{t("prompt.text")}</p>
  //         </ScrollAnimation>
  //       </div>
  //       <div className="w-40-l w-100 flex justify-end items-center">
  //         <div className="w-100 w-auto-l tc">
  //           <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
  //             <a
  //               href={`tel:${t("prompt.tel")}`}
  //               className="grow dib f4 fw6 no-underline bg-white hover-bg-navy black hover-white pv3 ph4"
  //             >
  //               {t("prompt.button")}
  //             </a>
  //           </ScrollAnimation>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default Prompt;
