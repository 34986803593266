import React from "react";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

import "../css/Presentation.css";

const PresentationBlock = ({ tKey, text, delay }) => {
  const { t } = useTranslation();
  return (
    <div className="w-33-l w-100 pa3">
      <ScrollAnimation animateIn="animate__fadeIn" delay={delay} animateOnce={true}>
        <h4 className="section-presentation-title">{t(`${tKey}.title`)}</h4>
        <div className="w-10 bt bw1" />
        <p className="section-presentation-text">{t(`${tKey}.text`)}</p>
      </ScrollAnimation>
    </div>
  );
};

const Presentation = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="section-presentation">
        <h2 className="f2 tc">{t("presentation.header")}</h2>
        <div className="flex flex-wrap">
          <PresentationBlock delay={100} tKey={"presentation.blocks.0"} />
          <PresentationBlock delay={200} tKey={"presentation.blocks.1"} />
          <PresentationBlock delay={300} tKey={"presentation.blocks.2"} />
          <PresentationBlock delay={400} tKey={"presentation.blocks.3"} />
          <PresentationBlock delay={500} tKey={"presentation.blocks.4"} />
          <PresentationBlock delay={600} tKey={"presentation.blocks.5"} />
        </div>
      </div>
    </div>
  );
};

export default Presentation;
