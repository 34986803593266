import React from "react";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

import "../css/Services.css";

const ServicesParagraph = ({ text, separator = true }) => {
  const { t } = useTranslation();
  return (
    <>
      <p className="tc f4 b section-services-paraph">{t(text)}</p>
      {separator && <div className="w-10 bt bw1 center" style={{ borderColor: "#00404A" }} />}
    </>
  );
};

const Services = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="section-services center flex-l flex-column">
        <h3 className="f2 tc">{t("services.header")}</h3>
        <div className="flex-l">
          <div className="w-50-l w-100">
            <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
              <img src="/images/inclusive1-v2.png" alt={t("alts.smartphone2")} />
            </ScrollAnimation>
          </div>
          <div className="w-50-l w-100 flex flex-column justify-center ph3">
            <ServicesParagraph text={"services.box1.0"} />
            <ServicesParagraph text={"services.box1.1"} />
            <ServicesParagraph text={"services.box1.2"} separator={false} />
          </div>
        </div>

        <div className="flex flex-column-reverse flex-row-l mt3">
          <div className="w-50-l w-100 flex flex-column justify-center ph5">
            <ServicesParagraph text={"services.box2.0"} />
            <ServicesParagraph text={"services.box2.1"} separator={false} />
          </div>
          <div className="w-50-l w-100">
            <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
              <img src="/images/inclusive2-v2.jpg" alt={t("alts.women")} />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
