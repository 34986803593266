import React from "react";
import { useTranslation } from "react-i18next";

import "../css/Banner.css";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <div className="w-100 cover bg-center relative overflow-hidden banner">
      <div className="absolute top-0 left-0 bottom-0 w-100 h-100 dn db-l ghb">
        <video autoPlay loop muted playsInline>
          <source src={"/Special_request_Website.m4v"} type="video/mp4" />
        </video>
      </div>

      <div className="flex flex-column flex-row-l mw9-l center justify-start items-center h-100 absolute top-0 left-0 bottom-0 right-0">
        <div className="base-gray-bg light w-100 ma2-l pa4 banner-box">
          <img src="/images/logo-premium.png" alt="Ziegler Request Logo" className="banner-logo" />
          <div className="banner-subtitle">{t("siteMetadata.name")}</div>
          <h2 className="banner-title">{t("banner.header")}</h2>
          <div className="w-20 bt bw1" />
          <p className="banner-text">{t("banner.text")}</p>
        </div>

        <div className="w-100 dn-l">
          <video autoPlay loop muted playsInline height="240">
            <source src={"/Special_request_Website.m4v"} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default Banner;
