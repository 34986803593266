import React from "react";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

import "../css/Director.css";

const Director = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="w-100" style={{ backgroundImage: `url(/images/Bandeau03_SR.jpg)` }}>
        <div className="section-director center flex-l justify-between pa3 pa5-l mt5">
          <div className="w-40-l w-100">
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
              <img src="/images/Image_bandeau03_SR-v2.jpg" alt={t("alts.president")} />
            </ScrollAnimation>
          </div>
          <div className="w-50-l w-100 flex justify-center items-center flex-column">
            <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true}>
              <p className="section-director-text">{t("director.text1")}</p>
              <div className="section-director-separator"></div>
              <p className="section-director-name">{t("director.text2")}</p>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Director;
