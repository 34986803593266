import React from "react";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

import "../css/PremiumServices.css";

const PremiumBox = ({ title, bullets }) => {
  const { t } = useTranslation();
  return (
    <div className="ba b--white white ma2 premium-box">
      <div className="premium-box-title">{t(title)}</div>
      <div className="premium-box-separator"></div>
      {bullets.map((text, idx) => (
        <div key={idx} className="premium-box-list">
          {t(text)}
        </div>
      ))}
    </div>
  );
};

const PremiumServices = () => {
  const { t } = useTranslation();
  return (
    <div className="w-100 bg-black white pa3 cover" style={{ backgroundImage: `url(/images/premium-solutions.jpg)` }}>
      <div className="mw9 center flex-l flex-column">
        <ScrollAnimation animateIn="animate__fadeIn" duration={1} animateOnce={true}>
          <h3 className="f2 tc">{t("premium.header")}</h3>
        </ScrollAnimation>

        <div className="flex-l mv5">
          <div className="flex-l flex-column w-33-l w-100 justify-center items-center">
            <ScrollAnimation animateIn="animate__fadeInLeft" duration={1} animateOnce={true}>
              <PremiumBox
                title={"premium.blocks.0.title"}
                bullets={["premium.blocks.0.texts.0", "premium.blocks.0.texts.1", "premium.blocks.0.texts.2"]}
              />
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInLeft" duration={1} delay={200} animateOnce={true}>
              <PremiumBox
                title={"premium.blocks.1.title"}
                bullets={["premium.blocks.1.texts.0", "premium.blocks.0.texts.1"]}
              />
            </ScrollAnimation>
          </div>

          <div className="flex flex-column w-33-l w-100 justify-around items-center">
            <div className="flex ">
              <img src="/images/logo-premium.png" alt="premium" />
            </div>
            <div className="flex premium-slogan">
              Everything,
              <br />
              everywhere
              <br />
              at anytime.
            </div>
          </div>

          <div className="flex-l flex-column w-33-l w-100 justify-center  items-center">
            <ScrollAnimation animateIn="animate__fadeInRight" duration={1} animateOnce={true}>
              <PremiumBox
                title={"premium.blocks.2.title"}
                bullets={["premium.blocks.2.texts.0", "premium.blocks.2.texts.1", "premium.blocks.2.texts.2"]}
              />
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInRight" duration={1} delay={200} animateOnce={true}>
              <PremiumBox
                title={"premium.blocks.3.title"}
                bullets={["premium.blocks.3.texts.0", "premium.blocks.3.texts.1"]}
              />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumServices;
